import moment from 'moment';
import React, { useState } from 'react'
import { patch } from '../../utils/axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { setLogin, setSelectedVendor, setUseJourneyVendor } from '../../state';
import LoadingButton from '../LoadingButton';

function VersusVendor({ data }) {
    const dispatch = useDispatch();
    const Token = useSelector((state) => state.token);
    const { default_vendor } = useSelector((state) => state.user);
    const [isProcessing, setIsProcessing] = useState(false);
    const [processingVendor, setProcessingVendor] = useState(null);
    const postSelectedVendorId = (item) => {
        setIsProcessing(true);
        setProcessingVendor(item);
        patch(
            `/user/update`,
            {
                user_selected_vendor_id: item,
                vendor_id: item,
            },
            {},
            Token
        )
            .then((res) => {
                dispatch(
                    setLogin({
                        user: res.response.data.user,
                        token: res.response.data.user?.token,
                        user_type: res.response.data.user?.user_type,
                        app_vendor_id: res.response.data.user?.app_vendor_id,
                        user_selected_vendor_id: res.response.data.user.default_vendor.id,
                    })
                );
            })
            .catch((err) => {
                setProcessingVendor(null);
                console.error("Error posting user selected vendor ID:", err);
            })
            .finally(() => setProcessingVendor(null))
    };

    return (
        <div
            // className={`mt-3 grid gap-7 w-[90%] mx-3 p-4 ${data.length === 2 ? 'lg:grid-cols-3' : 'lg:grid-cols-2'
            //     } justify-center self-center md:grid-cols-2 grid-cols-1`}
            className={`mt-3 gap-7 mx-3 p-4 flex md:flex-row flex-col justify-center lg:flex-nowrap flex-wrap`}
        >
            {data.map((vendor, index) => (
                <div
                    key={index}
                    // className=" flex flex-col justify-between border shadow-lg rounded-lg p-4 bg-white transform transition-transform duration-300 hover:scale-105"
                    className=" flex flex-col justify-between border shadow-lg rounded-lg p-4 bg-white transform transition-transform duration-300 hover:scale-105 lg:w-[33.33%] md:w-[48%] w-full"
                >
                    <div className="flex flex-col items-center mb-4">
                        <img
                            src={vendor.logo || require('../../assets/Png/placeholderImg.jpeg')}
                            alt={vendor.vendorName}
                            className="w-32 h-32 object-cover rounded-full mb-4 shadow-md"
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = require('../../assets/Png/placeholderImg.jpeg');
                            }}
                        />
                        <h3 className="text-lg font-semibold mb-2">{vendor.title || 'N/A'}</h3>
                    </div>

                    <div className="p-4 bg-slate-200 rounded-lg w-full h-[70%]">
                        <h2 className="font-semibold mb-2">LSV & Prices:</h2>
                        {vendor.seasonal_pricings?.length ? (
                            vendor.seasonal_pricings.map((item, index) => (
                                <div key={index} className="mb-2">
                                    <p>
                                        <span className="font-medium">{item.category.name || 'N/A'}:</span>
                                    </p>
                                    <div className='flex flex-row mb-2 pl-5'>
                                        <p className="font-semibold text-green-700">Start Date:</p>
                                        <span>{moment(item.start_date).format('MMM, DD YYYY') || 'N/A'}</span>
                                    </div>
                                    <div className='flex flex-row mb-2 pl-5'>
                                        <p className="font-semibold text-red-700">End Date:</p>
                                        <span>{moment(item.end_date).format('MMM, DD YYYY') || 'N/A'}</span>
                                    </div>
                                    <div className='flex flex-row mb-2 pl-5'>
                                        <p className="font-semibold text-blue-600">Price:</p>
                                        <p> ${item.value || 'N/A'}</p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="pl-5">No pricing available</p>
                        )}

                        <p className='mb-2'>
                            <span className="font-semibold">Min Rental Days:</span> {vendor.minimum_rentals_days || 'N/A'}
                        </p>
                        <p className='mb-2'>
                            <span className="font-semibold">Ratings:</span>{' '}
                            {'⭐'.repeat(vendor.average_ratings) + '☆'.repeat(5 - vendor.average_ratings)}
                        </p>
                        <p >
                            <span className="font-semibold">Discounts:</span>
                        </p>
                        {vendor.discounts?.length ? (
                            vendor.discounts.map((item, index) => (
                                <div key={index} className="mb-2 pl-5">
                                    <p>
                                        <span className="font-normal">{item.name || 'N/A'}:</span> ${item.value || 'N/A'}
                                    </p>
                                </div>
                            ))
                        ) : (
                            <p className="pl-5">No discounts available</p>
                        )}

                        <p>
                            <span className="font-semibold">Taxes & Charges:</span>
                        </p>
                        {vendor.tax_and_charge?.length ? (
                            vendor.tax_and_charge.map((item, index) => (
                                <div key={index} className="mb-2 pl-5">
                                    <p>
                                        <span className="font-normal">{item.name || 'N/A'}:</span> ${item.value || 'N/A'}
                                    </p>
                                </div>
                            ))
                        ) : (
                            <p className="pl-5">No taxes or charges available</p>
                        )}
                    </div>
                    {processingVendor === vendor.id ? (
                        <LoadingButton
                            isLoading={isProcessing}
                            onClick={() => postSelectedVendorId(vendor.id)}
                        >
                            {default_vendor.id === vendor.id ? "Current Default Vendor" : "Set As Default"}
                        </LoadingButton>
                    ) : (
                        <button onClick={() => postSelectedVendorId(vendor.id)} className={` ${default_vendor.id === vendor.id ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-600'} text-white p-2 rounded mt-4 w-full align-bottom justify-items-end `} disabled={default_vendor.id === vendor.id ? true : false} >
                            {default_vendor.id === vendor.id ? "Current Default Vendor" : "Set As Default"}
                        </button>
                    )

                    }

                </div>
            ))}
        </div>
    )
}

export default VersusVendor