import React, { useState } from 'react';
import Sidecard from '../Home/Sidecard';
import Maincard from '../ContactVendor/Maincard';

const Card = () => {

    // const [activeComponent, setActiveComponent] = useState('A');

    // const renderComponent = () => {
    //     switch (activeComponent) {
    //         case 'A':
    //             return <Maincard />;
    //         default:
    //             return null;
    //     }
    // };

    const helpsupport = "/ContactVendor"

    return (
        <div className="w-[100%] md:py-8 py-3 flex justify-center  bg-Btncolor">
            <div className=" md:w-[30%] hidden md:block">
                {/* <Sidecard /> */}

                {
                    helpsupport ? <Sidecard helpsupport={helpsupport} />
                        : <Sidecard />
                }


            </div>
            <div className="md:w-[55%]  w-[90%] flex flex-col">
                <Maincard />
            </div>
        </div>
    );
};

export default Card;













